.ant-tabs-nav-wrap {
  padding-left: 8px;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}
.ant-drawer {
  pointer-events: none !important;
}
.ant-drawer > .ant-drawer-content-wrapper > .ant-drawer-content {
  background: transparent;
}
.ant-drawer-body {
  padding: 0;
  overflow: overlay;
}

#root {
  width: 100%;
}
* {
  pointer-events: auto;
}
@media (min-width: 600px) {
  /** 滚动条 */
  ::-webkit-scrollbar-button:horizontal:increment {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEA+QLMGAIMGAIMGAIMBAUIGQoZU5HAwB+ZgDtGYq3fgAAAABJRU5ErkJggg==);
  }
  ::-webkit-scrollbar-button:horizontal:increment:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEA+QLMGAIMGAIMGAIMBAUIGQoZU5HAwB+ZgDtGYq3fgAAAABJRU5ErkJggg==);
  }
  ::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAiSURBVHgBY2BEAxQJMKAJMKAJMKAJMOATgIkQMBTBooZfAH56AO2yINTrAAAAAElFTkSuQmCC);
  }
  ::-webkit-scrollbar-button:horizontal:decrement:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAiSURBVHgBY2BEAxQJMKAJMKAJMKAJMOATgIkQMBTBooZfAH56AO2yINTrAAAAAElFTkSuQmCC);
  }
  ::-webkit-scrollbar-button:vertical:increment {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAfSURBVHgBY2BEA7QTYIAChAoYH6EFxkeYwQBj0MelAH3GAO2C9+AaAAAAAElFTkSuQmCC);
  }
  ::-webkit-scrollbar-button:vertical:increment:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAfSURBVHgBY2BEA7QTYIAChAoYH6EFxkeYwQBj0MelAH3GAO2C9+AaAAAAAElFTkSuQmCC);
  }
  ::-webkit-scrollbar-button:vertical:decrement {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEAzQVYIAzYHwGGAvOZ4AyIXyECH1cCgB/GgDtpWTgqAAAAABJRU5ErkJggg==);
  }
  ::-webkit-scrollbar-button:vertical:decrement:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEAzQVYIAzYHwGGAvOZ4AyIXyECH1cCgB/GgDtpWTgqAAAAABJRU5ErkJggg==);
  }
  ::-webkit-scrollbar {
    height: 18px;
    width: 18px;
  }
  ::-webkit-scrollbar:disabled {
    display: none;
  }
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    cursor: pointer;
    background: transparent;
  }
  -webkit-scrollbar-button:horizontal:decrement,
  -webkit-scrollbar-button:horizontal:decrement:hover,
  -webkit-scrollbar-button:horizontal:increment,
  -webkit-scrollbar-button:horizontal:increment:hover,
  -webkit-scrollbar-button:vertical:decrement,
  -webkit-scrollbar-button:vertical:decrement:hover,
  -webkit-scrollbar-button:vertical:increment,
  -webkit-scrollbar-button:vertical:increment:hover {
    height: 18px;
    width: 18px;
    background-position: 50%;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 9px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c8c6c4;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #98a3a6;
  }
  ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    min-width: 50px;
  }
  /** 滚动条 END */
}
body {
  height: 100%;
  width: 100% !important;
  overflow: hidden !important;
  font-family: 'Noto Sans SC', sans-serif;
}
/** 外部样式 */
.ant-drawer-content-wrapper {
  background: white;
}
/** 外部样式 END */

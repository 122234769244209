.EditorPage-slider.ant-slider {
  height: 22px;
}
.EditorPage-slider.ant-slider > .ant-slider-rail,
.EditorPage-slider.ant-slider > .ant-slider-track {
  height: 14px;
  border-radius: 7px;
}
.EditorPage-slider.ant-slider > .ant-slider-rail {
  background: #391A52;
}
.EditorPage-slider.ant-slider > .ant-slider-track {
  background: linear-gradient(173.46deg, #7B94FE 2.95%, #9281FC 83.98%);
}
.EditorPage-slider.ant-slider > .ant-slider-handle {
  width: 24px;
  height: 24px;
  background: linear-gradient(180deg, #FFFFFF 0%, #E6D9FB 100%);
  box-shadow: 0px 6px 14px rgba(37, 0, 109, 0.377456);
  border: unset;
}
.EditorPage-slider.ant-slider > .ant-slider-handle:active {
  filter: brightness(0.9);
}
